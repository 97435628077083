import React, { useEffect } from 'react';
import PropTypes from "prop-types";
import { graphql, useStaticQuery } from 'gatsby';

import './index.scss';

import { datasMaker } from '../FakeDatas/datasMaker';
import { appMaker } from './makersFunc';

const ProjectMaker = ({ trigger_id }) => {
    const img = useStaticQuery(graphql`
        {
        
            arrow: file(relativePath: {eq: "projectM/up-arrow.svg"}) {
                publicURL
                name
            }
            arrow_hover: file(relativePath: {eq: "projectM/up-arrow-hover.svg"}) {
                publicURL
                name
            }
            
        }
    `);

    useEffect(() => {
        appMaker.init(datasMaker.length, trigger_id);
    }, [])

    return (
        <>
            <div id={trigger_id} className="ctn-maker custom-scrollbar">
                <section className="inner">
                    <div className="numbers-questions">
                        <span id="step-up"> 1 </span>
                        /
                        <span> {datasMaker.length} </span>
                    </div>
                    {
                        /* FORMULAIRE */
                    }
                    <form id="project" action="" method="post" >
                        <ul className="questions">
                        {
                            /* QUESTIONS */
                        }
                        {
                            datasMaker.map((data, key) => {
                                if (data.type === 'question-card') {
                                    return (
                                        <QuestionCard
                                            {...data}
                                            index={key}
                                            key={key}
                                        />
                                    );
                                }
                                else if (data.type === 'question-bullet') {
                                    return (
                                        <QuestionList
                                            {...data}
                                            index={key}
                                            key={key}
                                        />
                                    );
                                }
                                else (
                                    <> </>
                                )
                            })
                        }
                            
                            
                        </ul>
                    </form>
                </section>
                <div className="arrow-questions">
                    {
                        // ARROW UP
                    }
                    <div
                        className="arrow-questions_back arrow-question"
                        onClick={appMaker.handleClickUp} 
                    >
                        <img src={img.arrow.publicURL} alt={img.arrow.name} className="arrow-notHover" />
                        <img src={img.arrow_hover.publicURL} alt={img.arrow.name} className="arrow-hover" />
                    </div>

                    {
                        // ARROW DOWN
                    }
                    <div
                        className="arrow-questions_next arrow-question"
                        onClick={appMaker.handleClickDown}
                    >
                        <img src={img.arrow.publicURL} alt={img.arrow.name} className="arrow-notHover" />
                        <img src={img.arrow_hover.publicURL} alt={img.arrow.name} className="arrow-hover" />
                    </div>
                </div>
                {
                    // PROGRESS BAR
                }
                <div className="progress-bar">
                    <div id="my-bar" />
                    <div className="tooltip-bar" id="tooltip-progress">
                        <span id="step-progress"> 1 </span>
                        /
                        <span>{datasMaker.length}</span>
                    </div>
                </div>
                {
                    // BTN QUESTION NEXT ON MOBILE
                }
                <div className="next_mob" id="btn-next_mob">
                    <button
                        type={ appMaker.step >= datasMaker.length ? 'submit' : 'button' }
                        className="btn-next_mob"
                        onClick={(e) => {
                            appMaker.handleQuestionNext(e, null, document.querySelector('.question.current'));
                        }}
                    >
                        { appMaker.step >= datasMaker.length ? /*'Fais chauffer la CB mec!'*/'Recevoir le devis' : 'Question suivante' }
                    </button>
                </div>
            </div>
            {/* <div className="other-resolution">
                <p>Cette résolution n'est pas encore prise en charge.</p>
                <p>Si vous avez tourné votre tablette ou votre mobile il faut le remettre à la verticale</p>
            </div> */}
        </>
    );
};

ProjectMaker.prototype = {
    trigger_id: PropTypes.string.isRequired,
};

export default ProjectMaker;

/*
    ======= TPL QUESTION CARD =======
*/
const QuestionCard = ( props:any ) => {   
    const { index } = props;
    return (       
        <li className={`question${index == 0 ? ' current' : ''}`}>
            <h2> { props.title } </h2>
            <p> { props.text } </p>
            <ul className="question-options question-card">
                {
                    props.options.map((option, key) => (
                        <li className="option" key={key}>
                            <input
                                type="radio"
                                name={props.selector}
                                value={option}
                                id={`${props.selector}-${key}`}
                                className="btn-radio"
                            />
                            <label 
                                htmlFor={`${props.selector}-${key}`}
                                className="option-txt"
                                onClick={appMaker.handleCheckLabel}
                            >
                                {option}
                            </label>
                        </li>
                    ))
                }                
            </ul>
            <div className="next">
                <button
                    type={ index >= datasMaker.length ? 'submit' : 'button' }
                    className="btn_next"
                    onClick={appMaker.handleQuestionNext}
                >
                    { index + 1 >= datasMaker.length ? /*'Fais chauffer la CB mec!'*/'Recevoir le devis' : 'Question suivante' } 
                </button>
                <span>
                    Ou appuyer sur entrer
                </span>
            </div>
        </li>
    )
};

/*
    ======= TPL QUESTION BULLET =======
*/
const QuestionList = ( props:any ) => {    
    const { index } = props;
    return (
        <li className={`question${index == 0 ? ' current' : ''}`}>
            <h2> { props.title } </h2>
            <p> { props.text } </p>
            <ul className="question-options question-bullet custom-scrollbar">
                {
                    props.options.map((option, key) => (
                        <li className="option-bullet" key={ key }>
                            <div className="ctn-option">
                                <input
                                    type="checkbox"
                                    className="input-checkbox"
                                    name={`${props.selector}-${key}`}
                                    id={`${props.selector}-${key}`}
                                    data-group={props.selector}
                                    value={option}
                                />
                                <label
                                    htmlFor={`${props.selector}-${key}`}
                                    onClick={appMaker.handleCheckCase}
                                >
                                    {/* <div className="checkbox">
                                        
                                    </div> */}
                                    <div className="checkbox-active checkbox-empty" />
                                    { option }
                                </label>
                            </div>
                        </li>                
                    ))
                }                
            </ul>
            <div className="next">
                <button
                    type={ index >= datasMaker.length ? 'submit' : 'button' }
                    className="btn_next"
                    onClick={appMaker.handleQuestionNext}
                >
                    { index + 1 >= datasMaker.length ? /*'Fais chauffer la CB mec!'*/'Recevoir le devis' : 'Question suivante' }
                </button>
                <span>
                    Ou appuyer sur entrer
                </span>
            </div>
        </li>
    )
}




