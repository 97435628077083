export const datasMaker = [

    {
        title: 'Votre Projet',
        text: 'Quel type de site désirez-vous ?',
        type: 'question-card',
        selector: 'project-type',
        options: 
        [
            'Site vitrine',
            'E-commerce',
            'Application Web',
        ]
    },
    {
        title: 'Services',
        text: 'De quels services avez-vous besoin ? ',
        type: 'question-card',
        selector: 'services',
        options: 
        [
            'Design', 
            'Développement',
            'Les deux',
        ]
    },
    {
        title: 'Connexion',
        text: 'Comment souhaitez-vous que vos utilisateurs se connectent ?',
        type: 'question-bullet',
        selector: 'connexion-types',
        options: 
        [
            'Connexion classique ( mail + mot de passe )',
            'Login par réseaux sociaux (Google, Facebook...)',
            'Login avec authentification en 2 étapes (sms ou appli authentification)',
            'Je ne sais pas',
        ]
    },
    {
        title: 'Communication',
        text: 'Voulez-vous que vos utilisateurs puissent communiquer entre eux ?',
        type: 'question-bullet',
        selector: 'communication',
        options: 
        [
            'Messagerie interne',
            'Messagerie externe (intégration facebook, whatsapp...)',
            'Chat de groupe',
            'Appel voix et vidéo',
            'Je ne sais pas',
        ]
    },
    {
        title: 'Système de paiment',
        text: `Avez vous besoin d'accepter des paiements ?`,
        type: 'question-bullet',
        selector: 'paiement',
        options: 
        [
            'Gestion des cartes de paiement directement par le site',
            'Point de vente réel/ Click and collect',
            'Services de paiement externe (Stripe / Paypal / Mollie)',
            'Module de paiement de votre banque.',
            'Je ne sais pas', 
        ]
    },
    {
        title: 'Fonctionnalités',
        text: 'Autres fonctionnalités dont vous avez besoin ?',
        type: 'question-bullet',
        selector: 'functions',
        options: 
        [
            'QR Code',
            'Notifications push / alertes',
            'Recherche',
            'Blog/ Fil d\'actualité',
            'Avis et notes',
            'Prestation SEO',
            'Newsletter',
            'FAQs',
            'Maps',
            'Calculateurs (prix, surfaces, conversions, ...)',
            'Je ne sais pas', 
        ]
    },
    {
        title: 'Votre budget',
        text: 'Quel serait votre buget :',
        type: 'question-bullet',
        selector: 'budget',
        options: 
        [
           '500 ~ 1000',
           '1000 ~ 3000',
           '3000 ~ 10000',
           '10 000 et +',
        ]
    }
]
